import {Vue} from "vue-property-decorator"
import Config, {ConfigType} from "@/models/Config";
import {Any} from "json2typescript"
import ConstantTool from "@/services/tool/ConstantTool"
import {getModule} from "vuex-module-decorators";
import SessionModule from "@/store/SessionModule";
import JsonTool from "@/services/tool/JsonTool";
import Product from "@/models/Product";
import SnackbarModule from "@/store/SnackbarModule";
import ConfigResponse from "@/models/responses/ConfigResponse";

export default class ConfigService {

    static async getConfig(component: Vue, variable: string , config: string) {
        // @ts-ignore
        component.loading = true

        try {
            const response = await component.axios.get(ConstantTool.BASE_URL + "/api/config/" + config, {
                headers: { Authorization: getModule(SessionModule).session.token }
            })

            // @ts-ignore
            component[variable] = JsonTool.jsonConvert.deserializeObject(response.data, Config)
        } catch (e) {
            getModule(SnackbarModule).makeToast("Han ocurrido errores consultando las configuraciones.")
        } finally {
            // @ts-ignore
            component.loading = false
        }
    }

    static async getPublicConfigs(component: Vue) {
        // @ts-ignore
        component.loading = true
        try {
            const response = await component.axios.get(ConstantTool.BASE_URL + "/public/config")
            // @ts-ignore
            component.configs = JsonTool.jsonConvert.deserializeObject(response.data, ConfigResponse)
        } catch (e) {
            getModule(SnackbarModule).makeToast("Han ocurrido errores consultando las configuraciones.")
        } finally {
            // @ts-ignore
            component.loading = false
        }
    }

    static async patchConfig(component: Vue, key: ConfigType, config: Config) {
        // @ts-ignore
        component.loading = true
        try {
            const response = await component.axios.patch(ConstantTool.BASE_URL + "/api/config/" + key, config, {
                headers: { Authorization: getModule(SessionModule).session.token },
            })
        } catch (e) {

        } finally {
            // @ts-ignore
            component.loading = false
        }
    }


}